<template>
    <base-layout :page-title="loadedSwap ? loadedSwap.title : 'Loading...'">
        <div class="h-full">
            <h2 v-if="!loadedSwap">Could not find a swap for the given id.</h2>
            <h2 id="roomName" class="flex relative z-50 justify-center items-center pt-2 text-center text-gray-400 bg-white duration-150 transition-text" ref="roomName">Swap to</h2>
            <div class="overflow-hidden relative mx-auto h-full" id="productsStack">
                <ion-card
                    ref="vueswing"
                    v-for="swap in loadedSwap"
                    :key="swap"
                    :id="swap.id"
                    class="flex overflow-hidden absolute top-0 right-4 left-4 bottom-5 flex-col flex-wrap mx-auto max-w-sm rounded-xl transition-all duration-700 transform cursor-pointer scale-80 card text-grey-900"
                >
                    <swiper :pagination="{ clickable: true }" :sliderDrag="false" :noSwiping="true" :allowTouchMove="false" :loop="true" ref="gallery" class="disabled swiper-gallery" :navigation="{ nextEL: '.gallery-button-next', prevEL: '.gallery-button-prev' }">
                        <swiper-slide ref="slides" v-if="swap.product_image_main">
                            <ion-img :src="'https://www.savemoneycutcarbon.com/wp-content/uploads'+swap.product_image_secondary" class="object-cover object-center w-full h-auto hsm:hidden" alt="main image" ></ion-img>
                            <ion-img :src="'https://www.savemoneycutcarbon.com/wp-content/uploads'+swap.product_image_main" class="hidden object-cover object-center w-auto h-auto hsm:block" alt="main image" ></ion-img>
                        </swiper-slide>
                        <swiper-slide
                            v-for="image in swap.gallery"
                            :key="image"
                            :id="image.id"
                            class="bg-white"
                        >
                            <ion-img :src="'https://www.savemoneycutcarbon.com/wp-content/uploads'+image" class="mt-10"></ion-img>
                        </swiper-slide>
                    </swiper>

                    <div class="flex relative flex-col flex-wrap w-full h-full pointer-events-none">
                        <div class="px-2 pt-2 mt-auto w-full bg-transparent bg-gradient-to-b via-gray-900 to-gray-900 gradient sm:pt-4 sm:px-4 hsm:px-4 hsm:pt-4 text-gray-900">
                            <h2 class="w-full text-2xl font-bold pt-10">{{ swap.name.split("|")[0] }}</h2>
                            <div class="w-full hsm:min-h-36">
                                <ul class="flex flex-wrap gap-2 mt-3 text-xs sm:text-sm mb-3">
                                    <li
                                        class="px-2 py-1 text-white bg-gray-500 rounded-full opacity-95"
                                        v-for="usp in swap.usps"
                                        :key="usp"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" class="inline w-4 h-4">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0h42v42H0z"/>
                                                <path fill="#76BD1D" fill-rule="nonzero" d="M21 1.5c10.77 0 19.5 8.73 19.5 19.5S31.77 40.5 21 40.5 1.5 31.77 1.5 21 10.23 1.5 21 1.5Zm0 3C11.887 4.5 4.5 11.887 4.5 21S11.887 37.5 21 37.5 37.5 30.113 37.5 21 30.113 4.5 21 4.5Zm7.606 19.27a.692.692 0 0 1 .677.88c-1.038 3.487-4.345 6.042-8.28 6.042-3.93 0-7.236-2.555-8.282-6.043a.692.692 0 0 1 .68-.88h15.205Zm-13.837-6.924a2.077 2.077 0 1 1 0 4.154 2.077 2.077 0 0 1 0-4.154Zm12.462 0a2.077 2.077 0 1 1 0 4.154 2.077 2.077 0 0 1 0-4.154Z"/>
                                            </g>
                                        </svg>
                                        {{ usp.name }}
                                    </li>
                                </ul>
                                <div class="hidden hsm:block overflow-hidden">
                                    <p class="line-clamp-3">{{ swap.short_description }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex p-2 w-full pointer-events-auto button-row sm:p-4 hsm:p-4 invisible">
                            <div class="self-end w-full justify-between items-center flex">
                                <ion-button fill="clear" class="w-16 h-16 text-white rounded-full" @click="onClickOutLeft">
                                    <ion-img :src="require('@/assets/Icons/swap-reject-circle-col.svg')" class="w-16 h-16"></ion-img>
                                </ion-button>
                                <ion-button icon-only fill="clear" class="w-8.5 h-8.5 text-xl text-gray-300" @click="openModal(swap, 'info')">
                                    <ion-icon slot="icon-only" :icon="informationCircle"></ion-icon>
                                </ion-button>
                                <ion-button v-if="swap.gallery.length > 0" icon-only fill="clear" class="w-8.5 h-8.5 text-gray-300" @click="openModal(swap, 'gallery')">
                                    <ion-icon slot="icon-only" :icon="image" size="large"></ion-icon>
                                </ion-button>
                                <ion-button icon-only fill="clear" class="w-16 h-16 text-white rounded-full" @click="onClickOutRight">
                                    <ion-img :src="require('@/assets/Icons/swap-accep-circle-col.svg')" class="w-16 h-16"></ion-img>
                                </ion-button>
                            </div>
                        </div>
                    </div>
                </ion-card>
                <ion-card
                    v-if="loadedSwap.length === 0"
                    class="border-2 border-gray-200 bg-gray-100
                            flex items-center absolute top-0 bottom-5 flex-col flex-wrap content-end transition duration-700 transform cursor-pointer sm:max-w-sm"
                >
                    <div class="flex flex-col justify-center p-4 h-full">
                        <ion-img :src="require('@/assets/Icons/swap-happy.svg')" class="block mx-auto mb-3 w-10 h-10"></ion-img>
                        <div class="text-center">
                            <h2 class="text-lg font-bold text-green-500">You've Chosen a Swap!</h2>
                            <p class="pb-4 text-md text-smcc-grey-dark">Great work, you are on your way to helping the environment, thanks!</p>
                            <p class="text-md text-smcc-grey-dark">We'll notify you when there are more.</p>
                        </div>
                        <ion-button fill="clear" class="block mt-4 w-full font-bold text-green-500 bg-white rounded border-2 border-green-500 h-15" @click="$router.back()">
                            <ion-icon :src="require('@/assets/Icons/arrow-back.svg')" class="px-1 py-6 w-6 h-6"></ion-icon>Ready for your next swap?</ion-button>
                    </div>
                </ion-card>
            </div>
        </div>
    </base-layout>
</template>

<script>
import {IonButton, IonIcon, IonImg, modalController, IonCard} from '@ionic/vue';
import { defineComponent, inject } from "vue";
import { get, set } from '@/store/storage';
import BaseLayout from "@/components/base/BaseLayout";
import Hammer from "hammerjs";
import {useRouter} from 'vue-router';
import {imagesOutline, informationCircle, closeOutline, heart, checkmark, image} from 'ionicons/icons';
import Modal from '@/components/Modal.vue'
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';

export default defineComponent({
    grabbedCard: null,
    components: {
        BaseLayout,
        Swiper,
        SwiperSlide,
        IonButton,
        IonIcon,
        IonImg,
        IonCard
    },
    data() {
        const emitter = inject("emitter");   // Inject `emitter`
        return {
            Swiper,
            SwiperSlide,
            imagesOutline,
            informationCircle,
            closeOutline,
            heart,
            checkmark,
            image,
            declined_products: [],
            accepted_products: [],
            declined_swaps: [],
            roomId: this.$route.params.id,
            swapId: this.$route.params.product,
            loadedSwap: [],
            emitter
        }
    },
    setup() {
        const openModal = async (id, type) => {
            const modal = await modalController.create({
                component: Modal, //Modal is name of the component to render inside ionic modal
                componentProps: {
                    id: id,
                    type: type
                },
                swipeToClose: true,
                backdropDismiss: true,
            });
            return modal.present();
        };

        const swiper = new SwiperCore('.swiper-gallery', {
            modules: [Pagination, Navigation],
            effect: 'slide',
            direction: 'horizontal',
            speed: 500,
            allowSlideNext: true,
            allowSlidePrev: true,
            cssMode: true,
            noSwiping: true,
            loop: true,
            allowTouchMove: false
        })
        return {
            swiper,
            openModal,
            router: useRouter(),
        };
    },
    computed: {
        currentCard() {
            return this.loadedSwap[this.loadedSwap.length - 1];
        },
    },
    methods: {
        stackCount() {
            const stack = document.getElementById('productsStack');
            return stack.childElementCount;
        },
        computeDirection(fromX, fromY) {
            const isHorizontal = Math.abs(fromX) > Math.abs(fromY);

            const isLeftDirection = fromX < 0 ? 'left' : 'right';
            const isUpDirection = fromY < 0 ? 'up' : 'down';

            return isHorizontal ? isLeftDirection : isUpDirection;
        },
        remove(target) {
            setTimeout(() => {
                target.remove();
                this.loadedSwap=this.loadedSwap.filter(element => element !== target)
                this.loadedSwap.pop();
            }, 100)
        },
        restack(target) {
            setTimeout(() => {
                const stack = document.getElementById("productsStack");
                /**
                 * If we dont keep track of what cards are left the card gets positioned
                 * behind the stack in the wrong place
                 */
                if(this.stackCount() > 1) {
                    stack.insertBefore(target, stack.firstChild.nextSibling);
                }
                target.style['transform'] = `translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))`;
            }, 50)
        },
        /**
         * Click handler for decline action
         * @param ev
         */
        onClickOutLeft(ev) {
            const r = Math.floor(Math.random() * (25 - 10 + 1) + 10)
            this.grabbedCard = ev.target.closest(".card");
            this.grabbedCard.style['transform'] = `translate3d(-500px, 0, 0) translate(-500px, 0px) rotate(${r}deg)`;
            this.grabbedCard.style['transition'] = `all 0.7s 0.1s ease-out`;
            setTimeout(()=> {
                this.onThrowOutLeft(this.grabbedCard);
            },200);
        },
        /**
         * Click handler for accept action
         * @param ev
         */
        onClickOutRight(ev) {
            this.grabbedCard = ev.target.closest(".card");
            this.onThrowOutRight(this.grabbedCard);
        },

        /**
         * Declines a product to swap to
         *
         * Adds ID's of the product, the swap, and the room to
         * 'declines_products' storage key
         *
         * @param target
         */
        onThrowOutLeft(target) {
            const room = this.roomId;
            const swap = this.swapId;
            const product = target.id;
            /**
             * Append the current product to an array
             */
            this.declined_products.push({room, swap, product});
            /**
             * Set local storage of the current product
             */
            set('declined_products', this.declined_products);
            set('last_action', {set:'declined_products', value: {room, swap, product} });
            this.emitter.emit("action", true);
            target.style['transform'] = `translate3d(-1500px, 0px, 0px) rotate3d(0, 0, 0, 0deg)`;
            setTimeout(() => {
                this.remove(target)
            }, 100);
        },
        /**
         * Accept a Product to swap to
         *
         * Adds ID's of the product, the swap, and the room to
         * 'accepted_products' storage key
         *
         * @param target
         */
        onThrowOutRight(target) {
            const room = this.roomId;
            const swap = this.swapId;
            const product = target.id;
            /**
             * Append the current product to an array
             */
            this.accepted_products.push({room, swap, product});
            /**
             * Set local storage of the current product
             */
            set('accepted_products', this.accepted_products);
            set('last_action', {set:'accepted_products', value: {room, swap, product} });
            this.emitter.emit("action", true);
            target.style.transitionDuration = '500ms'
            target.style['transform'] = `translate3d(0, 0, 0) translate(500px, 0px) rotate(10deg)`;
            setTimeout(() => {
                this.remove(target)
            }, 100);
            //this.router.push({ path: this.$route.params.id + '/product/' + target.id + '/swaps', append: true })
            //this.restack(target)
        },
        onThrowOutDown({target}) {
            this.restack(target)
        },
        onThrowOutUp({target}) {
            this.restack(target)
        },
        onThrowOutEnd({target}) {
            this.reset(target);
        },
        changeTitle(revert = false, decision = '') {

            const title = this.$refs.roomName;
            const originalTitle = "Swap to";

            const acceptsvg = '<svg class="mr-1" width="20" height="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h20v20H0z"/><path d="M14.273 1.67c-1.702.054-3.023.913-3.989 2.13l-.046.059-.043-.056c-1.001-1.26-2.378-2.136-4.163-2.136-2.664 0-4.813 2.207-4.841 4.898-.045 4.416 2.72 7.777 8.256 11.526a1.41 1.41 0 0 0 1.583 0c5.536-3.75 8.3-7.11 8.255-11.526-.027-2.69-2.176-4.898-4.84-4.898l-.172.002zm.171 1.425c1.87 0 3.393 1.566 3.413 3.484v.175c-.034 3.191-1.881 5.849-5.633 8.722l-.417.315-.214.158-.44.317c-.224.16-.453.32-.688.48l-.227.154.01.008a46.53 46.53 0 0 1-1.146-.8l-.432-.317c-4.104-3.048-6.085-5.826-6.05-9.212.02-1.918 1.543-3.484 3.412-3.484 1.48 0 2.628.891 3.41 2.111l.089.143.069.123a.714.714 0 0 0 1.243.06l.08-.144.055-.093.06-.096c.778-1.213 1.926-2.104 3.406-2.104z" fill="#76BD1D" fill-rule="nonzero"/></g></svg>';
            const rejectsvg = '<svg class="mr-1" width="20" height="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h20v20H0z"/><g fill="#C63A3A" fill-rule="nonzero"><path d="M1.876 2.352a.714.714 0 0 1 .956-.049l.054.05 14.762 14.761a.714.714 0 0 1-.956 1.06l-.054-.05L1.876 3.362a.714.714 0 0 1 0-1.01z"/><path d="M16.638 2.352a.714.714 0 0 1 1.059.956l-.05.054L2.887 18.124a.714.714 0 0 1-1.06-.956l.05-.054L16.638 2.352z"/></g></g></svg>';

            if(decision === 'reject') {
                title.innerHTML = rejectsvg + "Swap Rejected!";
                title.classList.add('text-red-500');
                title.classList.remove('text-green-500');
            } else if(decision === 'liked') {
                title.innerHTML = acceptsvg + "Swap Liked!";
                title.classList.add('text-green-500');
                title.classList.remove('text-red-500');
            }

            if(revert === true) {
                console.log(originalTitle)
                title.innerHTML = originalTitle;
                title.classList.remove('text-red-500');
                title.classList.remove('text-green-500');
            }
        }
    },
    async mounted() {
        this.emitter.on("undo", async () => {   // *Listen* for event
            const action = await get('last_action');
            const object = JSON.stringify(action.value);
            const filter = this[action.set].filter(function(a){ return JSON.stringify(a) !== object });
            const setter = action.set;
            console.log(setter)
            await set(setter, filter);
            this.loadedSwap = await this.$store.getters.filterDeclinesAccepteds(this.$route.params.product);
        });
        this.hammer = new Hammer.Manager(this.$el, {
            inputClass: Hammer.TouchMouseInput,
            recognizers: [
                [Hammer.Tap],
                [Hammer.Pan],
            ],
        });
        this.hammer.on("tap", () => {
            console.log(this.$refs.gallery)
        });
        this.hammer.on("pan", (ev) => {
            const card = ev.target.closest(".card");
            if (card === null) {
                return;
            }
            this.grabbedCard = card;
            const x = ev.deltaX;
            const y = ev.deltaY;
            const r = this.rotation(x, y, ev.target, 25);
            card.style.transitionDuration = '0ms'
            card.style['transform'] = `translate3d(0, 0, 0) translate(${x}px, ${y}px) rotate(${r}deg)`;
            if (ev.deltaX >= 190) {
                this.changeTitle(false, 'liked');
                card.style['border-color'] = 'rgba(118, 189, 29, var(--tw-border-opacity))';
            }
            if (ev.deltaX <= -190) {
                this.changeTitle(false, 'reject');
                card.style['border-color'] = 'rgba(198, 58, 57, var(--tw-border-opacity))';
            }
        });
        this.hammer.on("panend", (ev) => {
            const card = this.grabbedCard;
            if (ev.deltaY >= 50 || ev.deltaY <= -50) {
                this.restack(card)
            }
            if (ev.deltaX >= 200) {
                this.onThrowOutRight(card);
            }
            if (ev.deltaX <= -200) {
                this.onThrowOutLeft(card);
            }
            card.style['transform'] = `translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))`;
            card.style['border-color'] = 'rgba(118, 189, 29, var(--tw-border-opacity))';
            card.style.transitionDuration = '700ms'
            this.changeTitle(true);
        });
        this.rotation = function (coordinateX, coordinateY, element, maxRotation) {
            const horizontalOffset = Math.min(Math.max(coordinateX / element.offsetWidth, -1), 1);
            const verticalOffset = (coordinateY > 0 ? 1 : -1) * Math.min(Math.abs(coordinateY) / 100, 1);
            return horizontalOffset * verticalOffset * maxRotation;
        };
    },
    async beforeCreate() {
        this.loadedSwap = await this.$store.getters.filterDeclinesAccepteds(this.$route.params.product);
        this.declined_products = await get('declined_products') || [];
        this.accepted_products = await get('accepted_products') || [];
        this.declined_swaps = await get('declined_swaps') || [];
    },
    async ionViewWillEnter() {
        this.loadedSwap = await this.$store.getters.filterDeclinesAccepteds(this.$route.params.product);
        this.declined_products = await get('declined_products') || [];
        this.accepted_products = await get('accepted_products') || [];
        this.declined_swaps = await get('declined_swaps') || [];
    },
});
</script>
<style scoped>
.card:last-child {
    @apply bg-gray-900 border-2 border-green-500 shadow-lg scale-100 text-white;
}
.card:last-child h2,
.card:last-child p {
    @apply text-white
}
.card:last-child .button-row {
    @apply bg-gray-900 visible
}
.card:last-child .gradient {
    @apply from-transparent via-gray-900 to-gray-900
}
.card:nth-last-child(2) {
    @apply shadow-md scale-90 translate-y-8 hsm:translate-y-8 hmd:translate-y-10 hlg:translate-y-10 hxl:translate-y-16  !important;
}
.card:nth-last-child(3) {
    @apply shadow translate-y-16 hsm:translate-y-16 hmd:translate-y-20 hlg:translate-y-20 hxl:translate-y-32 !important;
}

ion-button {
    padding: 0;
    --padding-start: 0;
    --padding-end: 0;
}

ion-icon {
    --ionicon-stroke-width: 16px;
}
/*! purgecss start ignore */
.ios #productsStack {
    @apply -mt-4
}
.swiper-container {
    @apply absolute z-0 w-full;
}
.swiper-pagination {
    @apply top-3
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    @apply mx-4
}
.swiper-pagination-bullet {
    @apply w-3 h-3 border border-white opacity-60 bg-black
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-white border-gray-700 !important
}
.swiper-wrapper .swiper-slide ion-img::part(image){
    object-fit: cover;
    height: auto;
}

.swiper-wrapper .swiper-slide:not(:first-child) ion-img::part(image){
    object-fit: contain;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
/*! purgecss end ignore */
</style>
<style>
.swiper-button-next{
    @apply right-0 top-0 bottom-0 w-1/2 h-1/2 z-50
}
.swiper-button-next:after{
    content: ''
}
.swiper-button-prev{
    @apply left-0 top-0 bottom-0 w-1/2 h-1/2 z-50 block
}
.swiper-button-prev:after{
    content: ''
}
</style>
